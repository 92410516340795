import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

/*
  Use this hook to get a value from the React Router state.
  React router state can be used to pass data when redirecting to a page within the web app or even externally (See RedirectPage.jsx component)

  @param {string} key - The key of the value to get from the state. Use a unique key from constants/route_state to avoid conflicts.
  @returns {any} The value from the state
*/
export const useRouterState = key => {
  const location = useLocation()
  return location?.state?.[key]
}

export const withRouter = Component => {
  return props => {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    return (
      <Component
        navigate={navigate}
        location={location}
        params={params}
        {...props}
      />
    )
  }
}
